var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    { attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title } },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loading } }),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", "align-self": "center" } },
            [
              _c("v-treeview", {
                attrs: {
                  active: _vm.active,
                  items: _vm.collections,
                  "item-key": "id",
                  activatable: ""
                },
                on: {
                  "update:active": function($event) {
                    _vm.active = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("v-icon", [_vm._v(" " + _vm._s(item.icon) + " ")])
                      ]
                    }
                  },
                  {
                    key: "append",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.children
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.setModel(item)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-plus")])],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8" } },
            [
              _c("emc-base-card", {
                attrs: {
                  icon: _vm.$route.meta.icon,
                  title: _vm.model.id
                    ? "Atualização do menu: " + _vm.model.name
                    : "Novo menu"
                },
                scopedSlots: _vm._u(
                  [
                    _vm.model.id
                      ? {
                          key: "tools",
                          fn: function() {
                            return [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ma-1",
                                  attrs: { color: "grey", outlined: "" }
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { left: "" } },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-key")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" " + _vm._s(_vm.model.id) + " ")
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null,
                    {
                      key: "card-text",
                      fn: function() {
                        return [
                          _c("v-text-field", {
                            attrs: { label: "Nome" },
                            model: {
                              value: _vm.model.text,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "text", $$v)
                              },
                              expression: "model.text"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Descrição" },
                            model: {
                              value: _vm.model.description,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "description", $$v)
                              },
                              expression: "model.description"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "URI" },
                            model: {
                              value: _vm.model.to,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "to", $$v)
                              },
                              expression: "model.to"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Icone" },
                            model: {
                              value: _vm.model.icon,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "icon", $$v)
                              },
                              expression: "model.icon"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Menu pai" },
                            model: {
                              value: _vm.model.menu_id,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "menu_id", $$v)
                              },
                              expression: "model.menu_id"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Ordem" },
                            model: {
                              value: _vm.model.menu_order,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "menu_order", $$v)
                              },
                              expression: "model.menu_order"
                            }
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      inset: "",
                                      dense: "",
                                      label: "Menu Administrativo"
                                    },
                                    model: {
                                      value: _vm.model.is_admin,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "is_admin", $$v)
                                      },
                                      expression: "model.is_admin"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      inset: "",
                                      dense: "",
                                      label: "Menu interno"
                                    },
                                    model: {
                                      value: _vm.model.is_internal,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "is_internal", $$v)
                                      },
                                      expression: "model.is_internal"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      inset: "",
                                      dense: "",
                                      label: "Menu ativo"
                                    },
                                    model: {
                                      value: _vm.model.active,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "active", $$v)
                                      },
                                      expression: "model.active"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              attrs: {
                                color: "primary",
                                loading: _vm.loadingSave,
                                disabled: _vm.loadingSave || !_vm.model.text
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Salvar")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "error",
                                loading: _vm.loadingDestroy,
                                disabled: _vm.loadingDestroy || !_vm.model.text
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.destroy()
                                }
                              }
                            },
                            [_vm._v("Excluir")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }